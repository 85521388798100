// 404Page.js
function NotFoundPage() {
    return (
      <div className="text-center mt-20">
        <h1 className="text-5xl">404</h1>
        <p className="text-xl mt-4">Page Not Found!</p>
      </div>
    );
  }
  
  export default NotFoundPage;
  